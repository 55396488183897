.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  height: 66px;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, .05);
  background-color: #fff;
  color: #999;
  z-index: 52;
  user-select: none;
}

.nav__branding {
  margin-bottom: 20px;
}

.nav__icon {
  width: 32px;
  height: 32px;
  color: #606f7b;
}

.nav__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  position: relative;
  color: #718096;
  text-decoration: none;
}

.navLink__active > .nav__text,
.navLink__active > svg {
  color: #008298;
}

.navLink__active::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #00859c;
  box-shadow: 0 0 4px 0 #00859c;
}

.nav__text {
  font-size: 0.75rem;
}

.nav__footer {
  display: none;
}

.copy {
  display: none;
}

@media (min-width: 768px) {
  .nav {
    top: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    width: 220px;
    height: auto;
    padding: 20px;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
  }

  .nav__icon {
    display: none;
  }

  .nav__link {
    display: block;
    flex-direction: initial;
    justify-content: initial;
    align-items: initial;
    flex: initial;
    padding: 0.25rem 0.625rem;
    border-radius: 0.25rem;
  }

  .nav__link:hover {
    background-color: #f5f5f5;
    color: #1a202c;
  }

  .navLink__active > .nav__text {
    color: #1a202c;
  }

  .navLink__active::after {
    display: none;
  }

  .nav__linkHome {
    display: none;
  }

  .nav__text {
    font-size: 1rem;
  }

  .nav__footer {
    display: block;
    margin-top: auto;
  }

  .climateChange {
    margin-bottom: 1rem;
  }

  .copy {
    display: block;
    border-top: 1px solid #f1f5f8;
    font-size: 12px;
    padding-top: 4px;
    text-align: center;
    color: #b8c2cc;
  }
}
