.header {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-content: stretch;
  justify-content: flex-end;
  align-items: baseline;
  height: 220px;
  padding: 1rem 1.5rem 2rem;
  background-image: linear-gradient(45deg, #00859c 0%, #06bbc8 100%);
}

.header__logo {
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 10px;
}

.header__title {
  margin-bottom: 0;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
}

.header__tagline {
  margin-bottom: 0;
  color: hsl(183, 70%, 84%);
  font-size: 0.875rem;
}

/* Wave */

.wave {
  color: #fff;
  overflow: hidden;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
}

.wave__svg {
  fill: currentColor;
  height: auto;
  margin-left: -1%;
  width: 102%;
}

@media (min-width: 576px) {
  .header__title {
    font-size: 1.875rem;
    letter-spacing: -1px;
  }
}

@media (min-width: 768px) {
  .header {
    height: 260px;
    padding-bottom: 3rem;
  }

  .header__logo {
    display: none;
  }

  .wave {
    width: calc(100% - 220px);
  }
}

@media (min-width: 1200px) {
  .header {
    padding-bottom: 3.5rem;
  }
}
